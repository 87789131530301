import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import AuthorizeURLBuilder from '../../../components/AuthorizeURLBuilder';
export const _frontmatter = {
  "product": "verify",
  "category": "Guides & Tools",
  "sort": 0,
  "title": "Authorize URL builder",
  "subtitle": "Use our URL builder to explore the various features offers by Criipto Verify and how to use them."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`You can create a new or use an existing application from `}<a parentName="p" {...{
        "href": "https://dashboard.criipto.com/applications"
      }}>{`Criipto Verify`}</a>{`, just copy relevant values to fields below.`}</p>
    <AuthorizeURLBuilder mdxType="AuthorizeURLBuilder" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      